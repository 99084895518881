<template>
  <div class="container">
    <van-nav-bar title="学习时长统计" left-arrow @click-left="$router.back()" />
    <div style="margin-left: 10px">
      <h3 style="margin: 10px 5px">
        <span style="color: brown">当前职级： {{ userInfo.grade }}</span>
        <span>&nbsp;&nbsp;&nbsp; 年度职级：{{ userInfo.yearGrade }}</span>
      </h3>
      <h3 style="margin: 10px 5px"></h3>
      <div
        style="
          margin: 10px 5px;
          height: 48px;
          font-size: 14px;
          line-height: 24px;
        "
      >
        <van-icon
          name="info-o"
        />授课和听课课时大家参考需要结合上年度职级和当年年度职级合理规划
      </div>
    </div>
    <div>
      <van-cell
        to="/article/detail?para=e9a97018-86cf-49b9-a8a5-5d10ec3536d4&type=101"
        is-link
      >
        <h3 style="margin-left: 0px">查看任职资格与授课制度</h3>
      </van-cell>
    </div>
    <van-divider> 今年总览 </van-divider>
    <div style="margin: 0 0 6px 9px">
      <span v-if="getCoureGrade">根据当前职级:{{ userInfo.grade }}</span>
      <span v-else>根据上年度职级 {{ userInfo.yearGrade }}</span>
    </div>
    <van-row>
      <van-col span="6" v-if="learnInfo.hasLearn">
        <div style="margin-left: 10px">
          <p>
            {{
              learnInfo.offlineLearnedLen +learnInfo.onlineLearnedLen2
            }}
            h
          </p>
          <p>已学</p>
        </div>
      </van-col>

      <van-col span="6" v-if="learnInfo.hasTeach">
        <p>{{ learnInfo.offlineTeachedLen }} h</p>
        <p>已授</p>
      </van-col>
      <van-col
        span="6"
        v-if="
          learnInfo.hasLearn &&
          learnInfo.learnLen -
            learnInfo.totalLearnedLen >
            0
        "
      >
        <p>
          {{
            learnInfo.learnLen -
            learnInfo.totalLearnedLen
          }}
          h
        </p>
        <p>未学</p>
      </van-col>

      <van-col
        span="6"
        v-if="
          learnInfo.hasLearn &&
          learnInfo.learnLen -
          learnInfo.totalLearnedLen
        "
      >
        <p>0 h</p>
        <p>未学</p>
      </van-col>

      <van-col span="6" v-if="learnInfo.hasTeach">
        <p v-if="learnInfo.teachLen - learnInfo.offlineTeachedLen>0">{{ learnInfo.teachLen - learnInfo.offlineTeachedLen }} h</p>
        <p v-if="learnInfo.teachLen - learnInfo.offlineTeachedLen<=0">0 h</p>
        <p>未授</p>
      </van-col>
    </van-row>

    <van-collapse v-model="activeName" accordion>
      <van-collapse-item :title="getUserGradeMax5Title()" name="1">
        <div>
          <van-cell
            v-for="item in browsingTime.browsingItemList"
            :key="item.itemId"
          >
            <van-col span="24">
              <van-col span="14">
                {{ item.title }}
              </van-col>
              <van-col span="4">
                {{ item.itemType == 7 ? "线上课程" : "线下回看" }}
              </van-col>
              <van-col span="6">
                <div style="float: right">
                  {{ (item.actualTime / 60).toFixed(0) }} 分钟
                </div>
              </van-col>
            </van-col>
          </van-cell>
        </div>
      </van-collapse-item>
      <van-collapse-item
        :title="'线下学习' + learnInfo.offlineLearnedLen + '小时'"
        name="2"
      >
        <div>
          <van-cell v-for="item in articles" :key="item.itemId">
            <van-col span="24">
              <van-col span="14">
                {{ item.title }}
              </van-col>
              <van-col span="4">
                {{ item.teacherUserName }}
              </van-col>
              <van-col span="6">
                <div style="float: right">{{ item.courseLength }} 小时</div>
              </van-col>
            </van-col>
          </van-cell>
        </div></van-collapse-item
      >
    </van-collapse>

    <div v-if="listYear.length > 0">
      <van-divider> 历年统计 </van-divider>
      <table>
        <tr>
          <th>年度</th>
          <th>线下学</th>
          <th>线上学</th>
          <th>已授</th>
        </tr>
        <tbody>
          <tr v-for="(item, index) in listYear" :key="index">
            <td>
              {{ item.courseTimeBegin.substring(0, 10) }} 至
              {{ item.courseTimeEnd.substring(0, 10) }}
            </td>
            <td>{{ item.offlineLearnedLen }}h</td>
            <td>{{ item.onlineLearnedLen }}h</td>
            <td>{{ item.offlineTeachedLen }}h</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { getUserInfo, getUserLearn, GetUserLearnYear } from "@/api/user";
import { getBrowsingTimebyUser } from "@/api/school";
import { getListSignInAndComment } from "@/api/courseoffline";
export default {
  name: "UserCourseTime",
  data() {
    return {
      activeName: "",
      userInfo: {},
      learnInfo: {
        totalLearnedLen: 0,
        onlineLearnedLen: 0,
        onlineLearnedLen2: 0,
        learnLen: 0,
        offlineLearnedLen: 0,
        teachLen: 0,
        offlineTeachedLen: 0
      },
      browsingTime: {},
      articles: [],
      listYear: [],
      activeNames: ["1"],
      pagePara: {
        current: 1,
        pageSize: 100,
        sorterType: 0,
        fromType: 1,
        filterData: {
          title: "",
          status: 0,
          schoolClass: 0,
          schoolClass2: 0,
        },
      },
    };
  },
  created() {
    this.getUser();
    this.GetUserLearnYear();
    this.getLearn();
    this.getBrowsingTime();
    this.getOfflineTime();
  },
  methods: {
    getCoureGrade() {
      if (this.userInfo.grade.indexOf(this.learnInfo.gradeLevel) >= 0) {
        return true;
      }
      return false;
    },

    async GetUserLearnYear() {
      var aresult = await GetUserLearnYear();
      this.listYear = aresult.data.data;
    },
    async getUser() {
      var aresult = await getUserInfo();
      this.userInfo = aresult.data.data;
    },
    async getLearn() {
      var result = await getUserLearn();
      this.learnInfo = result.data.data;
    },
    async getBrowsingTime() {
      var result = await getBrowsingTimebyUser();
      this.browsingTime = result.data.data;
    },
    async getOfflineTime() {
      var aresult = await getListSignInAndComment({ ...this.pagePara });
      this.articles = [];
      this.articles.push(...aresult.data.data);
    },
    //是否高于P5
    getUserGradeMax5() {
      var max5 = false;
      if (this.userInfo.grade != undefined) {
        if (
          this.userInfo.grade.indexOf("M2") > -1 ||
          this.userInfo.grade.indexOf("M3") > -1 ||
          this.userInfo.grade.indexOf("M4") > -1 ||
          this.userInfo.grade.indexOf("5") > -1 ||
          this.userInfo.grade.indexOf("6") > -1 ||
          this.userInfo.grade.indexOf("7") > -1 ||
          this.userInfo.grade.indexOf("8") > -1 ||
          this.userInfo.grade.indexOf("9") > -1 ||
          this.userInfo.grade.indexOf("10") > -1 ||
          this.userInfo.grade.indexOf("11") > -1 ||
          this.userInfo.grade.indexOf("12") > -1
        ) {
          max5 = true;
        }
      }
      return max5;
    },

    getUserGradeMax5Title() {
      if (this.getUserGradeMax5()) {
        return (
          "线上已学" +
          this.learnInfo.onlineLearnedLen +
          " 小时,不计入职级学习"
        );
      } else {
        return (
          "线上已学" +
          this.learnInfo.onlineLearnedLen +
          " 小时,计入职级学习" +
          this.learnInfo.onlineLearnedLen2
        );
      }
    },
    getOnlineGradeTime() {
      var gradetime = 0;
      if (!this.getUserGradeMax5()) {
        gradetime = (this.browsingTime.totalTime / 60 / 60 / 2).toFixed(2);
        if (gradetime > this.learnInfo.learnLen / 2) {
          gradetime = this.learnInfo.learnLen / 2;
        }
      }
      return gradetime;
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

table,
td,
th {
  border: 1px solid #ddd;
  text-align: left;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin: 0 2px;
}

th {
  padding: 5px;
}
td {
  padding: 5px;
}
</style>